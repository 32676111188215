<template>
  <div class="">
    <el-card shadow="never">
      <el-button type="primary" @click="edit(), (editType = 0)"
        >绑定探索</el-button
      >
      <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :total="total"
      >
        <template v-slot:table>
          <el-table-column align="center" type="index" label="序号" />
          <el-table-column prop="userName" align="center" label="封面">
            <div slot-scope="scope">
              <img class="w-70 h-56 ra-4" :src="scope.row.icon" alt="" />
            </div>
          </el-table-column>
          <el-table-column align="center" label="程序标题">
            <div slot-scope="scope">
              <a
                class="co-51 size-14 fw-5 txt-udl pointer"
                :href="scope.row.url"
                target="_blank"
              >
                {{ scope.row.title }}
              </a>
            </div>
          </el-table-column>
          <!-- <el-table-column prop="url" align="center" label="地址 " /> -->
          <el-table-column prop="createTime" align="center" label="更新时间">
            <div slot-scope="scope">
              {{ getTimer(scope.row.createTime) }}
            </div>
          </el-table-column>
          <el-table-column align="center" label="绑定状态">
            <div slot-scope="scope">
              <el-popconfirm
                title="是否切换访问状态？"
                @confirm="isSwitch(scope.row)"
              >
                <el-switch
                  class="pointer"
                  disabled
                  slot="reference"
                  v-model="scope.row.status"
                >
                </el-switch>
              </el-popconfirm>
            </div>
          </el-table-column>
          <el-table-column prop="userName" align="center" label="操作">
            <div slot-scope="scope">
              <el-button type="text" @click="edit(scope.row), (editType = 1)"
                >设置</el-button
              >
              <el-button type="text" @click="openLog(scope.row)"
                >预览</el-button
              >
              <el-popconfirm
                title="是否确定删除？"
                class="ml-12"
                @confirm="delateList(scope.row.id)"
              >
                <el-button slot="reference" type="text">删除</el-button>
              </el-popconfirm>
            </div>
          </el-table-column>
        </template>
      </commonTable>
    </el-card>
    <el-dialog
      title="绑定探索"
      :visible.sync="centerDialogVisible"
      @close="(centerDialogVisible = false), (this.fileList = [])"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      width="50%"
      center
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="78px"
      >
        <el-form-item label="选择内容">
          <el-select
            v-model="ruleForm.bId"
            placeholder="请选择"
            @change="changeApply"
            style="width: 100%"
          >
            <el-option
              v-for="item in applyList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标题">
          <el-input v-model="ruleForm.title" placeholder="请输入标题">
          </el-input>
        </el-form-item>
        <!-- <el-form-item label="地址" prop="url">
            <el-input
              v-model="ruleForm.url"
              placeholder="请输入绑定项目url地址"
            >
              <el-button
                slot="append"
                @click="(previewDialogVisible = true), getapplyList()"
                >近迁应用管理</el-button
              >
            </el-input>
          </el-form-item> -->

        <el-form-item label="封面" prop="coverImg">
          <el-upload
            :class="{
              'avatar-uploader': true,
              'avatar-coverImg': true,
              disabled: uploadDisabled,
            }"
            :action="$store.state.uploadingUrl"
            list-type="picture-card"
            :on-progress="handProgress"
            :on-success="handleAvatarSuccessCoverImg"
            :on-remove="handleRemove"
            :before-upload="beforeUploadIcon"
            :file-list="fileList"
            :limit="limit"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              请上传图片宽高比为5：4的jpg或png图片，大小不超过150kb
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="(centerDialogVisible = false), (this.fileList = [])"
          >取 消</el-button
        >
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="绑定应用"
      :visible.sync="previewDialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      :show-close="false"
      :lock-scroll="false"
      width="50%"
      center
    >
      <el-form>
        <!-- <el-form-item label="应用分类">
            <el-input v-model="ruleForm.title" placeholder="请输入标题">
            </el-input>
          </el-form-item> -->
        <el-form-item label="应用名称">
          <el-select v-model="applyValue" placeholder="请选择">
            <el-option
              v-for="item in applyList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="previewDialogVisible = false">取 消</el-button>
        <el-button @click="applySubmit" type="primary">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 预览 -->
    <el-dialog
      title=""
      :visible.sync="yulanDialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      :show-close="false"
      :lock-scroll="false"
      width="425px"
      top="40px"
      class="dialog-box"
    >
      <div class="handset-box">
        <h3 class="title">{{ iframeTitle }}</h3>
        <iframe :src="iframeUrl" frameborder="0"></iframe>
        <div class="btn-box">
          <div
            class="close"
            @click="(yulanDialogVisible = false), (iframeUrl = '')"
          ></div>
          <div class="title" @click="copyUrl"></div>
          <div class="back"></div>
          <div class="qr"></div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
  
  <script>
import commonTable from "@/components/common/commonTable";
import {
  exploreAdd,
  exploreQueryPage,
  exploreUpdate,
  exploreDeleteById,
  exploreSwitchExplore,
} from "@/api/explore";
import { blendQueryPage } from "@/api/drawingBed";
export default {
  components: {
    commonTable,
  },
  data() {
    return {
      applyValue: "",
      applyList: [],
      centerDialogVisible: false,
      dialogDetailVisible: false,
      previewDialogVisible: false,
      goodsList: [],
      goodsListCurrentPage: 1, //当前页
      goodsListPageSize: 10, //显示条数
      goodsListLoading: false, //表格加载
      goodsListTotal: 0, //总条数
      ruleForm: {
        title: "",
        icon: "",
        url: "",
        videoGoods: [],
      },
      fileList: [],
      rules: {},
      options: [
        {
          value: "选项1",
          label: "自定义媒体页",
        },
        {
          value: "选项2",
          label: "短视频",
        },
      ],
      value: "",
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 1, //总条数
      bEdit: false,
      editType: 0,
      limit: 1,
      uploadType: false,
      iframeUrl: "",
      iframeTitle: "",
      yulanDialogVisible: false,
    };
  },
  computed: {
    uploadDisabled() {
      return this.fileList.length !== 0;
    },
  },
  mounted() {
    this.getData();
    this.getapplyList();
  },
  methods: {
    // 预览
    openLog(item) {
      this.yulanDialogVisible = true;
      this.iframeUrl = item.url;
      this.iframeTitle = item.title;
    },
    // 预览--复制模型地址
    copyUrl() {
      var input = document.createElement("input"); // 创建input对象
      input.value = this.iframeUrl; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("成功复制地址");
    },
    getapplyList() {
      let data = {
        pageNum: 1,
        pageSize: 100,
      };
      blendQueryPage(data).then((res) => {
        this.applyList = res.data.records;
      });
    },
    // 选择应用
    changeApply(e) {
      console.log(e, "88888");
      this.applyList.forEach((el) => {
        if (e == el.id) {
          this.ruleForm.url = el.realUrl;
          this.ruleForm.title = el.title;
          this.ruleForm.icon = el.icon;
          this.fileList = [{ name: "img", url: el.icon }];
        }
      });
    },
    applySubmit() {
      if (this.applyValue == "") {
        this.$message.warning("请选择内容!");
        return;
      }
      this.applyList.forEach((el) => {
        if (this.applyValue == el.id) {
          this.ruleForm.url = el.fileUrl;
          this.ruleForm.icon = el.icon;
          this.fileList = [{ name: "img", url: el.icon }];
          if (this.ruleForm.title == "") {
            this.ruleForm.title = el.title;
          }
        }
      });
      this.previewDialogVisible = false;
    },
    getTimer(time) {
      let data = new Date(time).toLocaleString();
      // return data.getFullYear()
      return data;
    },
    // 访问状态
    isSwitch(item) {
      let type = item.status ? 0 : 1;
      console.log(type, "99999");
      exploreSwitchExplore({ Eid: item.id, status: item.status ? 0 : 1 }).then(
        (res) => {
          if (res.code == 200) {
            this.getData();
          }
        }
      );
    },
    // 删除列表
    delateList(id) {
      exploreDeleteById({ Eid: id }).then((res) => {
        if (res.code == 200) {
          this.getData();
          this.$message.success("删除成功");
        }
      });
    },
    // 获取列表
    getData() {
      this.loading = true;
      exploreQueryPage({
        pageSize: this.pageSize,
        pageNum: this.currentPage,
      }).then((res) => {
        this.loading = false;
        this.tableData = res.data.records;
        this.total = res.data.total;
        this.tableData.forEach((el) => {
          if (el.status == 0) {
            el.status = false;
          } else {
            el.status = true;
          }
        });
      });
    },
    edit(item) {
      this.centerDialogVisible = true;
      if (item) {
        let str=JSON.stringify(item);
        this.ruleForm =JSON.parse(str);
        this.ruleForm.bId = this.ruleForm.bid;
        console.log(this.ruleForm, "item");
        this.editType = 1;
        this.applyValue = "";
        this.fileList = [{ name: "img", url: item.icon }];
      } else {
        this.editType = 0;
        this.ruleForm = {};
        this.fileList = [];
      }
    },
    // 添加编辑列表
    submit() {
      if (this.ruleForm.title == "") {
        this.$message.warning("请添加标题！");
        return;
      }
      console.log(this.ruleForm, "this.ruleForm");
      if (this.editType == 0) {
        this.ruleForm.url = this.ruleForm.url + "&title=" + this.ruleForm.title;
        exploreAdd(this.ruleForm).then((res) => {
          if (res.code == 200) {
            this.$message.success("更新成功");
            this.centerDialogVisible = false;
            this.getData();
          }
        });
      } else {
        if (this.ruleForm.url) {
          console.log();
          let arr = this.ruleForm.url.split("&");
          if (arr.length > 1) {
            arr[1] = "title=" + this.ruleForm.title;
            this.ruleForm.url = arr.join("&");
          } else {
            this.ruleForm.url =
              this.ruleForm.url + "&title=" + this.ruleForm.title;
          }
          // console.log(this.ruleForm.url);
        }
        this.ruleForm.Eid = this.ruleForm.id;
        // exploreUpdate(this.ruleForm).then((res) => {
        //   if (res.code == 200) {
        //     this.$message.success("更新成功");
        //     this.centerDialogVisible = false;
        //   }
        // });
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData();
    },
    /**@method 上传时回调 */
    handProgress(event, file, fileList) {
      if (this.isUpdate) {
        this.$message.closeAll();
        this.$message({
          message: "正在上传",
        });
      }
      this.isUpdate = false;
    },
    /**@method 上传成功 */
    handleAvatarSuccessVideo(res, file) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.isUpdate = true;
      this.ruleForm.url = `https://cdn-oss.jquen.com/${res.data}`;
    },
    beforeAvatarUploadVideo(file) {
      const isLt2M = file.size / 1024 / 1024 < 50;
      if (!isLt2M) {
        this.$message.error("zip压缩包大小不能超过 50MB!");
      }
      return isLt2M;
    },
    beforeUploadIcon(file) {
      console.log(file, "file.size");
    },
    handleAvatarSuccessCoverImg(res, file, fileList) {
      this.fileList = fileList;
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.icon = `https://cdn-oss.jquen.com/${res.data}`;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.ruleForm.icon = "";
      this.fileList = [];
    },
    queding() {
      this.ruleForm.videoGoods = [];
      this.multipleSelection.forEach((item) => {
        let data = {
          companyId: item.companyId,
          createdTime: item.createTime,
          goodsId: item.goodsId,
          videoGoodId: item.goodsId,
          videoId: this.ruleForm.videoId,
          name: item.goodsName,
          price: (item.price / 100).toFixed(2),
        };
        this.ruleForm.videoGoods.push(data);
        this.dialogDetailVisible = false;
      });
    },
    goodsListCurrentChange(val) {
      this.goodsListCurrentPage = val;
      this.getGoodsInfoPC();
    },
    goodsListSizeChange(val) {
      this.goodsListPageSize = val;
      this.getGoodsInfoPC();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    goodsDelete(index) {
      this.ruleForm.videoGoods.splice(index, 1);
    },
  },
};
</script>
  <style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 180px;
  text-align: center;
}

::v-deep .avatar-uploader .el-upload--text {
  width: auto;
  height: auto;
  border: none;

  p {
    color: $color-1;
    text-decoration: underline;
  }
}

::v-deep .avatar-coverImg .el-upload--text {
  width: 100px;
  height: 100px;
}

::v-deep .avatar-coverImg .avatar-uploader-icon {
  line-height: 160px;
}

.select-goods-list {
  margin-bottom: 20px;
  border-radius: 40px;
  box-sizing: border-box;
  padding: 0 20px;
  background: #ffffff;
  transition: all 0.4s;
}

.select-goods-list:hover {
  background: #f5f5f6;
  transition: all 0.4s;
  cursor: pointer;
}

::v-deep .disabled .el-upload--picture-card {
  display: none;
}

::v-deep .el-switch__core {
  cursor: pointer !important;
}

::v-deep .avatar-coverImg .el-upload--picture-card {
  width: 200px;
  height: 160px;
}

::v-deep .avatar-coverImg .el-upload-list__item {
  width: 200px;
  height: 160px;
}
// 预览
.dialog-box {
  user-select: none;

  ::v-deep.el-dialog {
    box-shadow: none;
    background: transparent;
  }
}
.handset-box {
  width: 375px;
  height: 766px;
  background-image: url(../../assets/images/iphonexr.png);
  background-size: cover;
  padding: 161px 14px 82px 11px;
  position: relative;

  // background-color: #ffffff;
  .title {
    position: absolute;
    left: 53px;
    top: 132px;
    width: 160px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    color: #2a2a2a;
    font-size: 17px;
    line-height: 18px;
  }

  .btn-box {
    width: 100%;
    position: absolute;
    left: 0px;
    top: 128px;

    img {
      width: 60px;
      height: 60px;
      display: block;
      margin-bottom: 10px;
      cursor: pointer;
    }

    .close {
      width: 38px;
      height: 28px;
      position: absolute;
      top: 0px;
      right: 28px;
      border-radius: 0px 50px 50px 0;
      cursor: pointer;
    }

    .title {
      width: 160px;
      height: 24px;
      position: absolute;
      top: 0px;
      left: 50px;
      cursor: pointer;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
    background-color: #ffffff !important;
  }
}
</style>